<template>
  <div class="main-window">
    <div class="header">
      <p @click="toggleDropdown">{{selected_model}}<span class="arrow">&#x25BC;</span></p>
      <div class="dropdown-content" :class="{show: isOpen}">
            <div class="option" v-for="(model,key) in models" :key="key" v-on:click="changeModel(key)">
              <span class="name">{{model.name}}</span>
              <br>
              <span class="description">{{ model.description }}</span>
            </div>
        </div>
    </div>

    <div class="chat" ref="chat">

      <div class="message" v-for="message in messages" :key="message.time">
        <div v-if="message.from === 'assistant'" class="bot-message">
          <p v-marked="message.text"></p>
        </div>
        <div v-else class="user-message">
          <p v-marked="message.text">{{ message.text }}</p>
        </div>
      </div>

    </div>

    <div class="message">
    </div>
    <div class="footer">
      <div class="input">
        <textarea @input="autoResize" placeholder="Zadejte sem svou zprávu..." rows="1" ref="textarea" @keydown="handleKeydown" v-model="textData"></textarea>
        <div class="sendButton fas fa-angle-up"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.001 10.978h-3.251c-.412 0-.75-.335-.75-.752 0-.188.071-.375.206-.518 1.685-1.775 4.692-4.945 6.069-6.396.189-.2.452-.312.725-.312.274 0 .536.112.725.312 1.377 1.451 4.385 4.621 6.068 6.396.136.143.207.33.207.518 0 .417-.337.752-.75.752h-3.251v9.02c0 .531-.47 1.002-1 1.002h-3.998c-.53 0-1-.471-1-1.002z" fill-rule="nonzero"/></svg></div>
      </div>
    </div>
  </div>
</template>

<script>

import { onMounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import 'highlight.js/styles/github-dark.css';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      textData: '',
      messages: [],
      isOpen: false,
      models: {},
      selected_model: '',
      code: `
      // Tvůj kód zde
      if (a == 1):
        print("TST")
      `
    }
  },
  setup() {
    const instance = getCurrentInstance();
    instance.appContext.config.globalProperties.$connectWebSocket();
    const socket = instance.appContext.config.globalProperties.$socket;


    const sendMessage = (message) => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(message);
        console.log('Message sent:', message);
      } else {
        // Přidej listener, který po připojení socketu odešle zprávu
        socket.addEventListener('open', () => {
          socket.send(message);
        }, { once: true });
      }
    };

    

    



    return {
      sendMessage
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    async changeModel(keyClicked) {
      for(let keye of Object.keys(this.models)) {
        if(keye === keyClicked) {
          this.models[keye].selected = true
        } else {
          this.models[keye].selected = false
        }
      }

      await axios.post(process.env.VUE_APP_BACKEND_URL + '/user/me/model', null, {
        params: {
          model: keyClicked
        },
        headers: {
          'Authorization': this.$store.getters.getTokenForAxios}})
          .then((response) => {
            console.log(response)
            this.isOpen = false
            this.changeSelectedModel()
          })
        

    },
    changeSelectedModel() {
      for(let key of Object.keys(this.models)) {
        if(this.models[key].selected) {
          this.selected_model = this.models[key].name
        }
      
      }
    },
    async get_models() {
      try {
        await axios.get(process.env.VUE_APP_BACKEND_URL + '/models', {
          headers: {
            'Authorization': this.$store.getters.getTokenForAxios
          }
        }).then((response) => {
          console.log(response)
          this.models = response.data
          this.changeSelectedModel()
        });
      } catch (error) {
        console.error('Načtení modelů se nezdařilo:', error);
      }
  },

    loadMessages() {
      // Načtení zpráv z API
      console.log("API_KEY", process.env.VUE_APP_BACKEND_URL)
      axios.get(process.env.VUE_APP_BACKEND_URL + '/user/me/chat/' + this.chatId, {
        headers: {
          'Authorization': this.$store.getters.getTokenForAxios
        }
      }).then((response) => {
        let raw_messages = response.data;
        
        console.log(raw_messages)

        for (let message of raw_messages) {
          var formated_message = message.message.replace(/(```[\s\S]*?```)|\n/g, function(match, p1) {
            if (p1) {
                // Pokud je match součástí tagů ''' XXX ''', necháme jej beze změny
                return p1;
            } else {
                // Pokud je match nový řádek mimo tagy, nahradíme jej za dva nové řádky
                return '\n\n';
            }
        });
          formated_message = message.message
          console.log(formated_message)
          this.messages.push({
            text: formated_message,
            from: message.type,
            time: message.timestamp

            
          });

          this.$nextTick(() => {
            const container = this.$refs.chat;
            if(container.scrollHeight != null){
              container.scrollTop = container.scrollHeight;
            }
            console.log(container.scrollTop, container.scrollHeight);
          });
          
        }
      }).catch((error) => {
        console.error('Načtení zpráv se nezdařilo:', error);
      });

      

    },
    
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();  // Zabraňte výchozímu chování Enter (nový řádek)
        this.send();
      }
      // Pro Shift + Enter necháme výchozí chování (přidání nového řádku)
    },
    autoResize(event) {
      const textarea = event ? event.target : this.$refs.textarea;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    send() {
      

      this.messages.push({
        text: this.textData,
        from: 'user',
        time: new Date().toLocaleTimeString()
      });
      console.log(this.textData)
      this.textData = ' '; // Smazání obsahu textarea po zpracování

      console.log(this.messages)

      this.getAnswer();

      var func = this.autoResize;
      setTimeout(function() {
        func()
      }, 100);
    },
    async getAnswer() {


      let messages = [];
      for(let mes of this.messages) {
        messages.push({
          role: mes.from,
          content: mes.text
        });
      }

      // Odeslání poslední zprávy	přes websocket
      let data = {
        "chat_id": this.chatId,
        "message": this.messages[this.messages.length - 1].text,
        "token": this.$store.getters.getToken
      }
      // Data to json
      this.sendMessage(JSON.stringify(data));
      //this.sendMessage(messages[messages.length - 1].content);   
    }
  },
  mounted() {

    // Zavoláme autoResize s malým zpožděním, aby byla zajištěna dostupnost DOM elementu
    this.$nextTick(() => {
      this.autoResize();
    });
    this.get_models()


  },
  computed: {
    chatId() {
      return this.$route.params.id;
    }
  },
  watch: {
    '$route.params.id': function(newId) {
      console.log('Chat ID has changed:', newId);
      // Můžeš zde provést další logiku při změně id
    },
  },
  created() {
    const instance = getCurrentInstance();
    const events = instance.appContext.config.globalProperties.$events;

    const handleMessage = (message) => {

      let data = JSON.parse(message);

      if(data.type == "first_part") {
        this.messages.push({
          text: data.content,
          from: 'assistant',
          time: new Date().toLocaleTimeString()
        });
      } else if(data.type == "next_part") {
        this.messages[this.messages.length - 1].text += data.content;
      }

      const container = this.$refs.chat;
      if(container != null){
        container.scrollTop = container.scrollHeight;
      }

      //call auto resize
      //console.log('Message received:', message, this.messages);
      //console.log(this.messages)

      };

    onMounted(() => {
      events.on('message', handleMessage);
      console.log(events, handleMessage)
    });



    let firstMessage = this.$store.getters.getFirstMessage;

    if(firstMessage != "") {

      //this.$parent.reloadKey = Date.now()

      this.messages.push({
        text: firstMessage,
        from: 'user',
        time: new Date().toLocaleTimeString()
      });

      this.getAnswer();
    }
    else {
      this.loadMessages();
    
    }

    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chat {
  width: 60%;
  height: 100%;
  overflow-y: auto;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll; /* Umožňuje vertikální scrollování */
  scrollbar-width: none; /* Pro Firefox */
}

.chat .message {
  display: block;
  color: white;
}

.chat .user-message {
  float: right;
  color: white;
  text-align: left;
  border-radius: 20px;
  background-color: #2f2f2f;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 85%;
}

.sendButton {
  background-color: #494949;
  fill: #121212;
  width: 30px;
  height: 30px;
  border-radius: 16px;
  cursor: pointer;
}

.main-window{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  color: #b4b4b4;
  padding-left:20px;
  padding-right: 20px;
  padding-top: 5px;
}
.header p {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}
.header .arrow {
  font-size:0.6rem;
  margin: auto;
  display: inline-block;
}

.header p:hover {
  background-color: #292929;
  border-radius: 10%;
}
.footer {
  width: 100%;   
  max-width: 1200px;   
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.footer .input {
  width: 55%;
  padding: 10px;
  border-radius: 20px;
  background-color: #2f2f2f;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer textarea {
  width: 90%;
  font-size: 16px;
  background-color: transparent;
  border:none!important;
  resize: none;
  color:aliceblue;
  overflow: hidden;
  outline: none;
  font-family: inherit;
}

.footer textarea:focus {
  border: none;
}
</style>

<style>

pre {
  background-color: #171717;
  padding: 10px;
  white-space: pre-wrap;
  border: solid thin #494949;
  border-radius: 10px;
}

.hljs {
  background-color: transparent;
}



</style>

<style>
.dropdown-content {
    display: none;
    position: absolute;
    background-color:#171717; ;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;
}
.dropdown-content .option {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
.dropdown-content .option:hover {
    background-color: #212121;    ;
}
.dropdown-content.show {
  display: block;
}

.dropdown-content .name {
  font-weight: bold;
  color: white;
}
.dropdown-content .description {
  font-size: 14px;
  color: #b4b4b4;
}
</style>
