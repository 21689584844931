import { createStore } from 'vuex';

function getTokenFromCookies() {
    const name = 'token=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

function setTokenToCookies(token) {
    const d = new Date();
    d.setTime(d.getTime() + (7*24*60*60*1000)); // Platnost cookie na 7 dní
    const expires = "expires="+ d.toUTCString();
    document.cookie = "token=" + token + ";" + expires + ";path=/";
}

const store = createStore({
  state: {
    // Zde definuj globální proměnné
    token: getTokenFromCookies(),
    first_message: "",
    backendUrl: "http://localhost:8000"
  },
  mutations: {
    // Mutace pro změnu stavů
    changeToken(state, token) {
      state.token = token;
      setTokenToCookies(token);
    },
    changeFirstMessage(state, first_message) {
      state.first_message = first_message;
    }
  },
  actions: {
  },
  getters: {
    // Gettery pro získávání stavů
    getToken(state) {
      return state.token;
    },
    getTokenForAxios(state) {
        return "Bearer " + state.token;
  },
  isAuthenticated: state => !!state.token,
  getFirstMessage(state) {
    let first_message = state.first_message;
    state.first_message = ""
    return first_message;
  }
}
});

export default store;