import { createRouter, createWebHistory } from 'vue-router'
import ChatWindow from '../components/ChatWindow.vue'
import LoginPage from '../components/LoginPage.vue'

const routes = [
  { path: '/', name: 'Home', component: ChatWindow },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/chat/:id', name: 'Chat', component: ChatWindow },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router