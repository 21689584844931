import { createApp } from 'vue';
import App from './App.vue';
import markedDirective from './directives/markedDirective';
import router from './router'
import store from './store';
import WebSocketPlugin from './plugins/websocket.js';
import { createVCodeBlock } from '@wdns/vue-code-block';
import 'katex/dist/katex.min.css';


const app = createApp(App);

const VCodeBlock = createVCodeBlock({
  // options
});

app.use(router)
app.use(store);
app.use(WebSocketPlugin);
app.use(VCodeBlock);


app.config.globalProperties.$token = ''

// Sledování tokenu
app.mixin({
    watch: {
      '$store.state.token': function (newToken) {
        console.log('Token changed to: ' + newToken);
        if (newToken === '') {
          this.$router.push('/login');
        }
      }
    }
  });

// Registrace direktivy
app.directive('marked', markedDirective);

app.mount('#app');