<template>

  <div class="main">
    <router-view/>
  </div>

</template>

<script>

export default {
  name: 'App',
  created() {
    if (!this.$store.getters.getToken) {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
html {
  height: 100%;
  font-family: ui-sans-serif,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Apple Color Emoji,Arial,Segoe UI Emoji,Segoe UI Symbol;
}
body {
  height: 100%;
}
#app {
  background-color: #212121;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
}
</style>
