<template>
    <div id="overlay">
        <div id="overlayContent">
            <h1>Vlastní nastavení</h1>
            <p>Zadejte vlastní instrukce pro model:</p>
            <textarea v-model="textData"></textarea>
            <button id="closeOverlay" v-on:click="closeCustomInstructions">Uložit</button>
        </div>
    </div>
</template>


<script>

import axios from 'axios'

export default {
    name: 'CustomInstructions',
    data() {
        return {
            textData: ''
        }
    },
    methods: {
        async closeCustomInstructions() {
            document.getElementById("overlay").style.display = "none";

            const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/user/me/custom_instructions', null, {
                params: {
                    custom_instructions: this.textData
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': this.$store.getters.getTokenForAxios
            }
                });

            console.log(response.data)


        },
        async loadCustomInstructions() {
            this.textData = this.$store.getters.getCustomInstructions;
            console.log(this.$store.getters.getTokenForAxios)
            const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/user/me/custom_instructions', {
            headers: {
                'Authorization': this.$store.getters.getTokenForAxios
        }
            });
        
            console.log(response.data)
            this.textData = response.data.custom_instructions
        }
    },
    created() {
        this.loadCustomInstructions();
    }
}
</script>

<style scoped>

    textarea {
        width: 95%;
        padding: 10px;
        min-height: 200px;
        margin-bottom: 12px;
        resize: none;
        background-color: #424242;
        color: #b4b4b4;
        font-size: 1.0rem;
        border: none;
        border-radius: 10px;
        outline: none;
        font-family: ui-sans-serif,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Apple Color Emoji,Arial,Segoe UI Emoji,Segoe UI Symbol
    }

    h1 {
        margin-top: 0px;
        border-bottom: solid 1px #b4b4b4;
    }

    #overlay {
        display: none; /* Skrytý ve výchozím stavu */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000;
    }

    #overlayContent {
        position: absolute;
        min-width: 40%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #212121;
        color: #b4b4b4;
        padding: 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
</style>