<template>
  <div class="chatWindow" >
    <LeftBar :key="reloadKey"/>
    <FirstChat v-if="isRoot" msg="Welcome to Your Vue.js App"/>
    <CurrentChat v-else :chatId="chatId" :key="$route.fullPath"/>
  </div>
</template>
  
  <script>
  import CurrentChat from './CurrentChat.vue'
  import FirstChat from './FirstChat.vue'
  import LeftBar from './LeftBar.vue'
  
  export default {
    name: 'ChatWindow',
    components: {
      FirstChat,
      CurrentChat,
      LeftBar
    },
    computed: {
    chatId() {
      return this.$route.params.id;
    },
    isRoot() {
      console.log(this.chatId)
      return !this.chatId;
    }
  }
  }
  </script>
  
  <style scoped>
  .chatWindow {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    position: relative;
    box-sizing: border-box;
  }
  </style>
  