<template>
  <div class="main-window">
    <div class="header">
      <p @click="toggleDropdown">{{selected_model}}<span class="arrow">&#x25BC;</span></p>
      <div class="dropdown-content" :class="{show: isOpen}">
            <div class="option" v-for="(model,key) in models" :key="key" v-on:click="changeModel(key)">
              <span class="name">{{model.name}}</span>
              <br>
              <span class="description">{{ model.description }}</span>
            </div>
        </div>
    </div>

    <div class="chat" ref="chat">
      <!-- Zde mohou být nějaké návrhy-->
    </div>

    <div class="message">

    </div>
    <div class="footer">
      <div class="input">
        <textarea @input="autoResize" placeholder="Zadejte sem svou zprávu..." rows="1" ref="textarea" @keydown="handleKeydown" v-model="textData"></textarea>
        <div class="sendButton fas fa-angle-up"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.001 10.978h-3.251c-.412 0-.75-.335-.75-.752 0-.188.071-.375.206-.518 1.685-1.775 4.692-4.945 6.069-6.396.189-.2.452-.312.725-.312.274 0 .536.112.725.312 1.377 1.451 4.385 4.621 6.068 6.396.136.143.207.33.207.518 0 .417-.337.752-.75.752h-3.251v9.02c0 .531-.47 1.002-1 1.002h-3.998c-.53 0-1-.471-1-1.002z" fill-rule="nonzero"/></svg></div>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      textData: '',
      isOpen: false,
      models: {},
      selected_model: ''
    }
  },
  methods: {
  
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();  // Zabraňte výchozímu chování Enter (nový řádek)
        this.send();
      }
      // Pro Shift + Enter necháme výchozí chování (přidání nového řádku)
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    autoResize(event) {
      const textarea = event ? event.target : this.$refs.textarea;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    async changeModel(keyClicked) {
      for(let keye of Object.keys(this.models)) {
        if(keye === keyClicked) {
          this.models[keye].selected = true
        } else {
          this.models[keye].selected = false
        }
      }

      await axios.post(process.env.VUE_APP_BACKEND_URL + '/user/me/model', null, {
        params: {
          model: keyClicked
        },
        headers: {
          'Authorization': this.$store.getters.getTokenForAxios}})
          .then((response) => {
            console.log(response)
            this.isOpen = false
            this.changeSelectedModel()
          })
        

    },
    changeSelectedModel() {
      for(let key of Object.keys(this.models)) {
        if(this.models[key].selected) {
          this.selected_model = this.models[key].name
        }
      
      }
    },
    async send() {

      try {
        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/createChat', {
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.$store.getters.getTokenForAxios
      }
        });

        this.$store.commit('changeFirstMessage', this.textData);
        this.$router.push('/chat/' + response.data.chat_id)
        console.log(response.data)

      } catch (error) {
        console.error('Přihlášení se nezdařilo:', error);
        // Zobrazit chybovou hlášku uživateli
      }
    },
    async get_models() {
      try {
        await axios.get(process.env.VUE_APP_BACKEND_URL + '/models', {
          headers: {
            'Authorization': this.$store.getters.getTokenForAxios
          }
        }).then((response) => {
          console.log(response)
          this.models = response.data
          this.changeSelectedModel()
        });
      } catch (error) {
        console.error('Načtení modelů se nezdařilo:', error);
      }
  },
},
  mounted() {
    this.get_models()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chat {
  width: 55%;
  height: 100%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll; /* Umožňuje vertikální scrollování */
  scrollbar-width: none; /* Pro Firefox */
}

.chat .message {
  display: block;
  color: white;
}

.chat .user-message {
  float: right;
  color: white;
  text-align: left;
  border-radius: 20px;
  background-color: #2f2f2f;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 85%;
}

.sendButton {
  background-color: #494949;
  fill: #121212;
  width: 30px;
  height: 30px;
  border-radius: 16px;
  cursor: pointer;
}

.main-window{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  color: #b4b4b4;
  padding-left:20px;
  padding-right: 20px;
  padding-top: 5px;
}
.header p {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}
.header .arrow {
  font-size:0.6rem;
  margin: auto;
  display: inline-block;
}

.header p:hover {
  background-color: #292929;
  border-radius: 10%;
}
.footer {
  width: 100%;   
  max-width: 1200px;   
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.footer .input {
  width: 55%;
  padding: 10px;
  border-radius: 20px;
  background-color: #2f2f2f;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer textarea {
  width: 90%;
  font-size: 16px;
  background-color: transparent;
  border:none!important;
  resize: none;
  color:aliceblue;
  overflow: hidden;
  outline: none;
  font-family: inherit;
}

.footer textarea:focus {
  border: none;
}

pre {
  background-color: #171717;
  padding: 10px;
  white-space: pre-wrap;
  border: solid thin #494949;
  border-radius: 10px;
}
</style>

<style>



</style>
