<template>
  <div class="loginWindow">
    <div class="login-container">
      <h2>Přihlášení</h2>
      <input type="text" v-model="username" @keyup.enter="focusPassword" placeholder="Přihlašovací jméno">
      <input type="password" v-model="password" @keyup.enter="login" ref="passwordInput" placeholder="Heslo">
      <button @click="login">Přihlásit</button>
    </div>
  </div>

</template>

<script>

import axios from 'axios'

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  components: {
  },
  methods: {
    focusPassword() {
      this.$refs.passwordInput.focus();
    },
    async login() {
      try {
        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/token', {
          username: this.username,
          password: this.password
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        if (response.data && response.data.access_token) {
          this.$store.commit('changeToken', response.data.access_token);
          //redirect to "/"
          this.$router.push('/')
          // Další akce po úspěšném přihlášení (např. přesměrování)
        }
      } catch (error) {
        console.error('Přihlášení se nezdařilo:', error);
        // Zobrazit chybovou hlášku uživateli
      }
    }
  }
}
</script>

<style scope>
  .loginWindow {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
  .login-container {
      background-color: #2f2f2f;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 300px;
      text-align: center;
  }
  .login-container input {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 10px 0;
      border: 1px solid #ddd;
      border-radius: 5px;
  }
  .login-container button {
      width: 100%;
      padding: 10px;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      color: white;
      cursor: pointer;
  }
  .login-container button:hover {
      background-color: #0056b3;
  }
  h2 {
    color: white;
  }
</style>
