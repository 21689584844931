import { marked } from 'marked';
import katex from 'katex';
import hljs from 'highlight.js';

function renderMath(text, options = {}) {
  text = text.replace("//", "/")
  try {
    return katex.renderToString(text, options);
} catch (error) {
    return text;
}
}

function applyRenderMath(text) {
  // eslint-disable-next-line no-useless-escape
  const regex = /\\[\[\(]([\s\S]+?)\\[\]\)]/g;
  return text.replace(regex, (match, p1) => renderMath(p1));
}

export default {
  mounted(el, binding) {
    var originalText = binding.value || el.textContent;
    //console.log("ZDE ORIGINAL TEXT", renderMath("Výsledek: 12 + 3 + \\frac{3}{3} + 12 \\times 2"));
    //var processedText = renderMath(originalText);
    //console.log("ZDE RENDER MATH", processedText);
    let pattern = /\$\$(.*?)\$\$/gs;
    originalText = originalText.replace(pattern, '\\[$1\\]');

    var processedText = applyRenderMath(originalText);
    processedText = marked(processedText);


    var parser = new DOMParser();
    var doc = parser.parseFromString(processedText, 'text/html');

    // Procházíme všechny `pre code` bloky a aplikujeme zvýraznění
    var blocks = doc.querySelectorAll('pre code');

    hljs.configure({
      useBR: true,
      tabReplace: '    ', // Nahrazení tabulátorů za čtyři mezery
    });

    blocks.forEach(block => {
      // Zvýraznění pomocí Highlight.js
      hljs.highlightElement(block);
    });

    // Extrahování HTML zpět do stringu
    var serializer = new XMLSerializer();
    var highlightedText = serializer.serializeToString(doc);

    //processedText = processedText;//.replace(/(?<!\n<\\p>)\n(?!\n)/g, '\n')
    el.innerHTML = highlightedText.replace(/(<p>[^]*?<\/p>)/g, function(match) {
      return match.replace(/(?<!\n)\n(?!\n)/g, '<br>');

  });
  },
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
        var originalText = binding.value || el.textContent;
        let pattern = /\$\$(.*?)\$\$/gs;
        originalText = originalText.replace(pattern, '\\[$1\\]');
    
        var processedText = applyRenderMath(originalText);
        processedText = marked(processedText);  
    
        var parser = new DOMParser();
        var doc = parser.parseFromString(processedText, 'text/html');
    
        // Procházíme všechny `pre code` bloky a aplikujeme zvýraznění
        var blocks = doc.querySelectorAll('pre code');
    
        hljs.configure({
          useBR: true,
        });
    
        blocks.forEach(block => {
          // Zvýraznění pomocí Highlight.js
          hljs.highlightElement(block);
        });
    
        // Extrahování HTML zpět do stringu
        var serializer = new XMLSerializer();
        var highlightedText = serializer.serializeToString(doc);
    
        //processedText = processedText;//.replace(/(?<!\n<\\p>)\n(?!\n)/g, '\n')
        el.innerHTML = highlightedText.replace(/(<p>[^]*?<\/p>)/g, function(match) {
          return match.replace(/(?<!\n)\n(?!\n)/g, '<br>');
    
      });
    }
  },
}
