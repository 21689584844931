// websocket.js
import mitt from 'mitt';

export default {
  install(app) {
    const emitter = mitt();
    let socket = null;

    function connectWebSocket() {
      socket = new WebSocket(process.env.VUE_APP_WS_URL);

      socket.onopen = function() {
        console.log('WebSocket is connected');
      };

      socket.onmessage = function(event) {
        emitter.emit('message', event.data);
      };

      socket.onclose = function() {
        console.log('WebSocket is closed');
      };

      socket.onerror = function(error) {
        console.error('WebSocket Error: ', error);
      };

      app.config.globalProperties.$socket = socket;
      app.config.globalProperties.$events = emitter;
    }

    app.config.globalProperties.$connectWebSocket = connectWebSocket;
  }
};